import React, { useState, useEffect } from "react";
import "../customCss/loanCalculator.css";
import Draggable from "react-draggable";
import {
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const LoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(""); // Kredi miktarı
  const [interestRate, setInterestRate] = useState(""); // Faiz oranı
  const [term, setTerm] = useState(""); // Vade süresi (ay)
  const [paymentPlan, setPaymentPlan] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false); // Dialog açık/kapalı durumu

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 768px altındaki ekranlar mobil kabul edilir
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Sayfa yüklendiğinde kontrol et
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatCurrency = (value) => {
    if (typeof value === 'number') {
        return value.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ;
    }
    return value; // Eğer string ise, olduğu gibi döndür
};



  const handleLoanAmountChange = (e) => {
    const input = e.target.value.replace(/[^\d]/g, ""); // Sadece rakamlar
    if (!input) {
      setLoanAmount("");
      return;
    }
    const formatted = input.replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Nokta ile gruplama
    setLoanAmount(formatted);
  };

  const calculateLoan = () => {
    if (!loanAmount || !interestRate || !term) {
      alert("Lütfen tüm alanları doldurun.");
      return;
    }

    const principal = parseFloat(loanAmount.replace(/\./g, "").replace(",", "."));
    const rate = parseFloat(interestRate.replace(",", ".")); 
    const numberOfPayments = parseInt(term);

    if (isNaN(principal) || isNaN(rate) || isNaN(numberOfPayments) || rate <= 0 || numberOfPayments <= 0) {
      alert("Geçerli bir değer girin.");
      return;
    }

    const monthlyInterestRate = rate / 100 / 12;
    const monthlyPayment = (principal * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

    if (!isFinite(monthlyPayment) || monthlyPayment <= 0) {
      alert("Hesaplama yapılamıyor. Lütfen değerleri kontrol edin.");
      return;
    }

    const plan = [];
    let remainingBalance = principal;

    for (let i = 1; i <= numberOfPayments; i++) {
        const interestPayment = remainingBalance * monthlyInterestRate;
        const principalPayment = monthlyPayment - interestPayment;
        remainingBalance -= principalPayment;

        // Değerleri formatlayarak ekle
        plan.push({
            month: i,
            monthlyPayment: monthlyPayment, // Formatlamadan önce sakla
            interestPayment: interestPayment,
            principalPayment: principalPayment,
            remainingBalance: remainingBalance > 0 ? remainingBalance : 0,
        });
    }
    const formattedPlan = plan.map(item => ({
        month: item.month,
        monthlyPayment: formatCurrency(item.monthlyPayment),
        interestPayment: formatCurrency(item.interestPayment),
        principalPayment: formatCurrency(item.principalPayment),
        remainingBalance: formatCurrency(item.remainingBalance),
    }));

    setPaymentPlan(formattedPlan);
    setOpen(true); // Ödeme planı penceresini aç
    setPaymentPlan(plan);
    setOpen(true); // Ödeme planı penceresini aç
};

  return (
    <Draggable disabled={isMobile}>
      <div
        className="loan-calculator"
        style={{
          margin: "auto",
          cursor: "move",
          border: "1px solid #ccc",
        }}
      >
        <Typography variant="h5">Kredi Hesaplayıcı</Typography>
        <div className="text-selectable">
          <TextField
            label="Kredi Tutarı (TRY)"
            value={loanAmount}
            onChange={handleLoanAmountChange}
            fullWidth
            margin="normal"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
          <TextField
            label="Faiz Oranı (%)"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            fullWidth
            margin="normal"
            inputProps={{
              inputMode: "decimal",
              pattern: "[0-9]*[.,]?[0-9]*",
            }}
          />
          <TextField
            label="Vade Süresi (ay)"
       
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            fullWidth
            margin="normal"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={calculateLoan}
          style={{ width: "100%", padding: "10px" }}
        >
          Hesapla
        </Button>

        {/* Ödeme Planı Dialog */}
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
          <DialogTitle>Ödeme Planı</DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ay</TableCell>
                    <TableCell>Aylık Ödeme</TableCell>
                    <TableCell>Faiz</TableCell>
                    <TableCell>Anapara</TableCell>
                    <TableCell>Kalan Borç</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentPlan.map((item) => (
                    <TableRow key={item.month}>
                      <TableCell>{item.month}</TableCell>
                      <TableCell>{formatCurrency(item.monthlyPayment)} ₺</TableCell>
                      <TableCell>{formatCurrency(item.interestPayment)} ₺</TableCell>
                      <TableCell>{formatCurrency(item.principalPayment)} ₺</TableCell>
                      <TableCell>{formatCurrency(item.remainingBalance)} ₺</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Draggable>
  );
};

export default LoanCalculator;
